import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';

import Count from '../empresas/Count';
//import MaterialTable from "material-table";
//import MaterialTable from "@material-ui/icons";



import { AddBox, ArrowDownward } from "@material-ui/icons";
import MaterialTable from "material-table";
//import 'bootstrap/dist/css/bootstrap.min.css';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


import Row from "./Row";

const multiregistros = {
  modulo: "",
  deporte: "",
  subdeporte: "",
  pais: "",
  departamento: "",
  ciudad: ""
};

function Empresas_creadas () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

 const {toggleNav,registerUser} = useContext(MyContext);
    //const baseUrlAd="https://www.plooy.club/apiFrameworks5/"
  const baseUrlAd="https://aliraweb.com/apiAlira2/empresas/"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    name:'',
    nit:'',
    ciudad:'',
    licencia:'',
    email:'',
    clave:'',
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(baseUrlAd)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

   const peticionPut = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("nit", frameworkSeleccionado.nit);
    f.append("ciudad", frameworkSeleccionado.ciudad);
    f.append("licencia", frameworkSeleccionado.licencia);
    f.append("email", frameworkSeleccionado.email);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "PUT_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.name= frameworkSeleccionado.name;
         framework.ciudad= frameworkSeleccionado.ciudad;
         framework.licencia= frameworkSeleccionado.licencia;
         framework.email= frameworkSeleccionado.email;
         framework.clave = frameworkSeleccionado.clave;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  /*const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }*/


  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("suspender", frameworkSeleccionado.suspender);
    f.append("METHOD", "PUT_administrador2");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.suspender= frameworkSeleccionado.suspender;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },
    
    {
      title:"EMPRESA",
      field: "name"
    },

    {
        title:"DOCUMENTO / NIT",
        field: "documento"
      },

      {
        title:"CIUDAD",
        field: "ciudad"
      },

      {
        title:"LICENCIA",
        field: "licencia"
      },
      {
        title:'EMAIL',
        field:'email'
      },

      
      {
        title:'TIPO USUARIO',
        field:'tipo_usuario'
      },

      {
        title:'ESTADO',
        field:'suspender'
      },

    
    
  ];


  
    
    if(isAuth && theUser.tipo_usuario =="admin")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="theme-panel">
<a href="javascript:;" data-toggle="theme-panel-expand" class="theme-collapse-btn"><i class="fa fa-cog"></i></a>
<div class="theme-panel-content" data-scrollbar="true" data-height="100%">
<h5>Color Empresarial</h5>

<div class="theme-list">
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-red" data-theme-class="theme-red" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Red">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-pink" data-theme-class="theme-pink" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Pink">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-orange" data-theme-class="theme-orange" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Orange">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-yellow" data-theme-class="theme-yellow" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Yellow">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-lime" data-theme-class="theme-lime" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Lime">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-green" data-theme-class="theme-green" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Green">&nbsp;</a></div>
<div class="theme-list-item active"><a href="javascript:;" class="theme-list-link bg-teal" data-theme-class="" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Default">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-cyan" data-theme-class="theme-cyan" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cyan">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-blue" data-theme-class="theme-blue" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Blue">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-purple" data-theme-class="theme-purple" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Purple">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-indigo" data-theme-class="theme-indigo" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Indigo">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-black" data-theme-class="theme-gray-600" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Black">&nbsp;</a></div>
</div>

<div class="theme-panel-divider"></div>
<div class="row mt-10px">
<div class="col-8 control-label text-dark fw-bold">
<div>DÍA / NOCHE </div>
<div class="lh-14">

</div>
</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-theme-dark-mode" id="appThemeDarkMode" value="1" />
<label class="form-check-label" for="appThemeDarkMode">&nbsp;</label>
</div>
</div>
</div>
<div class="theme-panel-divider"></div>

{/*<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Header Fixed</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-header-fixed" id="appHeaderFixed" value="1" checked />
<label class="form-check-label" for="appHeaderFixed">&nbsp;</label>
</div>
</div>
  </div> */}
<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Color barra superior</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-header-inverse" id="appHeaderInverse" value="1" />
<label class="form-check-label" for="appHeaderInverse">&nbsp;</label>
</div>
</div>
</div>
{/*<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Espacio menux</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-sidebar-fixed" id="appSidebarFixed" value="1" checked />
<label class="form-check-label" for="appSidebarFixed">&nbsp;</label>
</div>
</div>
  </div> */}
<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Espacio menu</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-sidebar-grid" id="appSidebarGrid" value="1" />
<label class="form-check-label" for="appSidebarGrid">&nbsp;</label>
</div>
</div>
</div>
<div class="row mt-10px align-items-center">
<div class="col-md-8 control-label text-dark fw-bold">Color menu</div>
<div class="col-md-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-gradient-enabled" id="appGradientEnabled" value="1" />
<label class="form-check-label" for="appGradientEnabled">&nbsp;</label>
</div>
</div>
</div>


</div>
</div>




<Count/>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">EMPRESAS CREADAS</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title=""
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

    {
        icon : 'edit',
        tooltip: 'Editar',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
  {
   icon : 'block',
   tooltip: 'Suspender',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>


{/*div class="alert alert-muted">
Textual form controls—like <code>&lt;input&gt;</code>s, <code>&lt;textarea&gt;</code>s, <code>&lt;select&gt;</code>s are styled with the <code>.form-control</code> and <code>.form-select</code> class. Included are styles for general appearance, focus state, sizing, and more.
</div> */}
<form >
    {/*
<div class="row mb-15px">
<label class="form-label col-form-label col-md-3">Nombre de la empresa</label>
<div class="col-md-9">
<input type="text" name="name" value={state.userInfo.name} onChange={onChangeValue} class="form-control mb-5px" placeholder="Mi empresa" />

</div>
</div>
<div class="row mb-15px">
<label class="form-label col-form-label col-md-3">NIT</label>
<div class="col-md-9">
<input type="number" name="nit" value={state.userInfo.nit} onChange={onChangeValue} class="form-control mb-5px" placeholder="0" />

</div>
</div>


<div class="row mb-15px">
<label class="form-label col-form-label col-md-3">Ciudad</label>
<div class="col-md-9">
<select class="form-select" name="ciudad"  value={state.userInfo.ciudad} onChange={onChangeValue}>
<option >Seleccionar..</option>
<option value="Ibague">Ibagué</option>
<option value="Espinal">Espinal</option>
</select>
</div>
</div>
<div class="row mb-15px">
<label class="form-label col-form-label col-md-3">Licencia</label>
<div class="col-md-9">
<select  class="form-select" name="licencia" value={state.userInfo.licencia} onChange={onChangeValue}>
<option >Seleccionar..</option>
<option value="Basico">Básico</option>
<option value="Premium">Premium</option>
<option value="Gold">Gold</option>
</select>
</div>
</div> */}
{/*<div class="row mb-15px">
<label class="form-label col-form-label col-md-3">Example textarea</label>
<div class="col-md-9">
<textarea class="form-control" rows="3"></textarea>
</div>
</div>*/}
{/*<div class="row mb-15px">
<label class="form-label col-form-label col-md-3">Camara de comercio</label>
<div class="col-md-9">
<input class="form-control" type="file" id="formFile" />
</div>
</div>
<div class="row mb-15px">
<label class="form-label col-form-label col-md-3">Email</label>
<div class="col-md-9">
<input type="email" class="form-control mb-5px" placeholder="Email de acceso" name="email" value={state.userInfo.email} onChange={onChangeValue} />

</div>
</div>
<div class="row mb-15px">
<label class="form-label col-form-label col-md-3">Contraseña</label>
<div class="col-md-9">
<input type="password" class="form-control mb-5px" placeholder="*****" name="clave"  value={state.userInfo.clave} onChange={onChangeValue} />
<small class="fs-12px text-gray-500-darker">La contraseña debe llevar minimo 8 caracteres</small>
</div>
</div>
<div class="row mb-15px">
<label class="form-label col-form-label col-md-3">Repetir la contraseña</label>
<div class="col-md-9">
<input type="password" name="pass" onChange={onChangeValue} value={state.userInfo.pass} class="form-control mb-5px" placeholder="*****" id="inputSuccess"/>
<small class="fs-12px text-gray-500-darker">La contraseña debe llevar minimo 8 caracteres</small>
</div>
</div>
<div class="row mb-15px">

<div class="col-md-12">
<button className="btn btn-primary" type="submit">Crear Empresa</button>
</div>



  
</div>*/}


{/* errorMsg != "" || successMsg != "" ? <div class="alert alert-info alert-dismissible fade show"> <strong>{errorMsg}{successMsg}</strong></div> : '' */}

</form>
</div>




{/*<div class="hljs-wrapper">
<pre><code class="html hljs language-xml"><span class="hljs-comment">&lt;!-- email --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">input</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"email"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"form-control"</span> <span class="hljs-attr">placeholder</span>=<span class="hljs-string">"Enter email"</span> /&gt;</span>

<span class="hljs-comment">&lt;!-- select --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">select</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"form-select"</span>&gt;</span>...<span class="hljs-tag">&lt;/<span class="hljs-name">select</span>&gt;</span>

<span class="hljs-comment">&lt;!-- multiple select --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">select</span> <span class="hljs-attr">multiple</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"form-select"</span>&gt;</span>...<span class="hljs-tag">&lt;/<span class="hljs-name">select</span>&gt;</span>

<span class="hljs-comment">&lt;!-- textarea --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">textarea</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"form-control"</span> <span class="hljs-attr">rows</span>=<span class="hljs-string">"3"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">textarea</span>&gt;</span>

<span class="hljs-comment">&lt;!-- file --&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">input</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"file"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"form-control"</span> /&gt;</span></code></pre>
</div>  */}

</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea suspender la empresa {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fa-solid fa-business-time"></i> <font Size="2">EDITAR EMPRESA</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">
   
     <div class="col-8">
     <label>Empresa:</label>
     <br/>
     <input type="text" className="form-control" name="name" onChange={handleChange}  value={frameworkSeleccionado && frameworkSeleccionado.name} />
     <br/>
     </div>

     <div class="col-4">
     <label>NIT</label>
     <br/>
     <input type="number" className="form-control" name="nit" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.nit} />
     
     </div>
     <div class="col-4">
     <label>Ciudad:</label>
     <br/>
     <select name="ciudad" class="form-control"  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.ciudad}>
     <option value='no' selected>Seleccione</option>
                    <option value="Ibague">Ibagué</option>
                    <option value="Espinal">Espinal</option>
                    
                  </select>  
                  <br />
     </div>
   
     <div class="col-4">
     <label>Licencia:</label>
     <br/>
     <select name="licencia" class="form-control"  onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.licencia}>
     <option value='no' selected>Seleccione</option>
                    <option value="Basico">Básico</option>
                    <option value="Premium">Premium</option>
                    <option value="Gold">Gold</option>
                    <option value="Demo">Demo</option>
                    
                  </select>  
                  <br />
     </div>
     <div class="col-4">
     <label>Tipo empresa:</label>
     <br/>
     <input type="text" readOnly className="form-control" name="tipo_ususario" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.tipo_usuario} />
     <br/>
     </div>
     <div class="col-7">
     <label>Email:</label>
     <br/>
     <input type="email" className="form-control" name="email" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.email} />
     
     </div>

     <div class="col-5">
     <label>Contraseña:</label>
     <br/>
     <input type="password"  className="form-control" name="clave" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.clave} />
     <br/>
     </div>
     


     
   

{/*} {rows.map((row, index) => (
          <Row
            {...row}
            onChange={(name, value) => handleOnChange(index, name, value)}
            onRemove={() => handleOnRemove(index)}
            key={index}
          />
))} */ }
     {/*   <button className="btn btn-success" onClick={handleOnAdd}>Agregar</button> */}
   </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary " onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Editar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Empresas_creadas
