import React from 'react'

function Routes() {
    return <></>
  }
 
  Routes.global = {
    url_login: 'https://aliraweb.com/apiAlira2/usuarios/'
  };
  
  export default Routes;
