import React, {useContext,useState,useEffect,useRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';


//import { AddBox, ArrowDownward } from "@material-ui/icons";
import MaterialTable from "material-table";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import SignatureCanvas from 'react-signature-canvas'


//import Row from "./Row";

const multiregistros = {
  documento: "",
  name: "",
  cargo: "",
  clave: "",
  id_empresa_usuario: "",
  nombre_empresa: "",
  documento_empresa: ""
};

function Hojas_de_vida () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

  const sigCanvas = useRef({})

  const limpiar_firma = () => sigCanvas.current.clear()
  const [imageURL,setImageURL] = useState(null)
  const guardar_firma = () => setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"))

  const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }


 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://aliraweb.com/apiAlira2/empresas_usuarios/empleados.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    documento:'',
    name:'',
    cargo:'',
    clave:'',
    id_empresa_usuario:'',
    nombre_empresa:'',
    documento_empresa:'',
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(`https://aliraweb.com/apiAlira2/empresas/hojas_de_vida.php?id_u=${global.idprincipal}`)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }


   const peticionPut = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("cargo", frameworkSeleccionado.cargo);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "PUT");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.name= frameworkSeleccionado.name;
         framework.documento= frameworkSeleccionado.documento;
         framework.cargo= frameworkSeleccionado.cargo;
        framework.clave = frameworkSeleccionado.clave;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }



  const peticionPost = async() =>{
    var f = new FormData();
    f.append("documento", frameworkSeleccionado.documento);
    f.append("name", frameworkSeleccionado.name);
    f.append("cargo", frameworkSeleccionado.cargo);
    f.append("id_empresa_usuario", theUser.id);
    f.append("nombre_empresa", theUser.name);
    f.append("documento_empresa", theUser.documento);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "PUT_suspender");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })


  }


  const [cargo, setCountry]= useState([]);

  useEffect( ()=>{

    const interval = setInterval(() => {
    const getcountry= async ()=>{
   const req= await fetch(`https://aliraweb.com/apiAlira2/empresas_usuarios/cargar_cargos.php?usuarioE=${global.idprincipal}`)
  // const req= await fetch("https://parqueoenvia.co/apiParqueoenvia/zonas/country.php");
      const getres= await req.json();
      console.log(getres);
      setCountry(await getres);
  
    }
    getcountry();

}, 2000);

return () => setInterval(interval);
  
  
   },[]);

  

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },
    
    {
      title:"DOCUMENTO",
      field: "documento"
    },

    {
        title:"NOMBRE",
        field: "name"
      },

    {
      title:'CARGO',
      field:'cargo'
    },
      
      {
        title:'TIPO USUARIO',
        field:'tipo_usuario'
      },

      
      {
        title:'ESTADO',
        field:'suspender',
       
       // render: rowData => <img src={rowData.url} style={{width: 50, borderRadius: '50%'}}/>   
    },
    
    
  ];


  
    
    if(isAuth && theUser.tipo_usuario =="empresa")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="row">




{/*<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>PUNTOS SUSPENDIDOS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_suspendidas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
  </div> */}

</div>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">{theUser.name} </h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<><i class="fas fa-user fa-2x"></i> </> }
backIconButtonText='Página anterior'
pagination
fixedHeader


actions={[

    {
        icon : 'attachment',
        tooltip: 'Hoja de vida',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
 
     
            /*  {
                  icon: 'block',
                  tooltip: 'suspender',
                  onClick: (event, framework) => seleccionarFramework(framework, "Eliminar")
              },
*/
             
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar'
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea inhabilitar al usuario {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar} style={{maxWidth:"800px"}} 

>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">MI HOJA DE VIDA</font></ModalHeader>
 <ModalBody>
   <div className="form-group">

   <form method="post" action="https://aliraweb.com/apiAlira2/hoja_de_vida/index.php" target="_parent" name="reset" encType='multipart/form-data'  noValidate>
   <div className="row">

   <div class="col-12">
   
  <center><img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://aliraweb.com/apiAlira2/hoja_de_vida/Imagenes/"+frameworkSeleccionado.foto_hojaVida} width="150px"/></center>
     <br/>
     </div>

  {/* <div class="col-5">
  

<div class="form-group">

                <label class="form-label" for="customFile">Foto de perfil</label>
                <input class="form-control border-0 form-control-clicked" id="txtFoto2" name="txtFoto2" type="file"
                accept="image/*"
                onChange={handleChange} />
              </div> 
            

     </div> */}

  
  
  
            <div class="col-4">
  
  <label>Nombres completos</label>
  <br/>
  <input type="text" className="form-control" name="cargo" onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.name} readOnly/>
  
  </div>


  <div className="col-3">
    

                 <label>Matrícula profesional N°</label>
                 <br/>
  <input type="text" className="form-control" name="matricula_profesional" onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.matricula_profesional}/>
               
            </div> 
  
           
       <div className="col-5">
    

    <label>Profesión</label>
    <br/>
<input type="text" className="form-control" name="profesion" onChange={handleChange}  
value={frameworkSeleccionado && frameworkSeleccionado.profesion}/>
  
</div> 

       
<div className="col-12">
<br/>
 <center><label>-------------------------------------- DATOS PERSONALES --------------------------------------- </label> </center>
 <br/>
 </div>

 <div class="col-4">
  
  <label>Fecha de nacimiento</label>
  <br/>
  <input type="date" className="form-control" name="fecha_nacimiento" onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.fecha_nacimiento}/>
  
  </div>
    
  <div class="col-4">
  
  <label>Lugar de nacimiento</label>
  <br/>
  <input type="text" className="form-control" name="lugar_nacimiento"  placeholder="Ibagué - Tolima" onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.lugar_nacimiento}/>
  
  </div>


  <div class="col-2">
  
  <label>Documento</label>
  <br/>
  <input type="text" className="form-control" name="documento"  placeholder="Ibagué - Tolima" onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.documento} readOnly/>
  
  </div>


  <div class="col-2">
  
  <label>Celular</label>
  <br/>
  <input type="number" className="form-control" name="celular"   onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.celular} />
  
  </div>


  <div class="col-12">
  <br/>
  <label>Dirección</label>
  <br/>
  <input type="text" className="form-control" name="domicilio_usuario"   onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.domicilio_usuario} />
  
  </div>


  <div class="col-6">
  <br/>
  <label>Email</label>
  <br/>
  <input type="email" className="form-control" name="email"   onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.email} />
  
  </div>


  <div class="col-3">
  <br/>
  <label>Facebook</label>
  <br/>
  <input type="text" className="form-control" name="facebook"   onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.facebook} />
  
  </div>


  <div class="col-3">
  <br/>
  <label>Instagram</label>
  <br/>
  <input type="text" className="form-control" name="instagram"   onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.instagram} />
  
  </div>
  <div class="col-12">
  <br/>
  <center><label>-------------------------------------- PERFIL PROFESIONAL --------------------------------------- </label> </center>
<br/>
</div>

<div class="col-12">

  
  <textarea type="text" rows={4} className="form-control" name="perfil_profesional"   onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.perfil_profesional} />
  
  </div>




  <div class="accordion accordion-style-three" id="accordionStyle3" >
  <br/>
              <div class="accordion-item" style={{backgroundColor: "white", borderColor:"black", borderWidth:"0px"}}>
                <div class="accordion-header " id="accordionSeven">
                 <center> <h6 class="shadow-sm rounded border" data-bs-toggle="collapse" data-bs-target="#accordionStyleSeven" aria-expanded="true" aria-controls="accordionStyleSeven">Formación academica
                    <svg class="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
                    </svg>
                  </h6></center>
                </div>
                <div class="accordion-collapse collapse " id="accordionStyleSeven" aria-labelledby="accordionSeven" data-bs-parent="#accordionStyle3" >
                  <div class="accordion-body">
                
                  <>
                  <div className='row'>
        <div class="col-2">
        
        <label>Año</label>
        <br/>
        <input type="text" className="form-control" name="f1_ano" 
        value={frameworkSeleccionado && frameworkSeleccionado.f1_ano}
        onChange={handleChange}  
        />
        
        </div>

        <div class="col-3">
        
        <label>Profesión</label>
        <br/>
        <input type="text" className="form-control" name="f1_profesion" 
         value={frameworkSeleccionado && frameworkSeleccionado.f1_profesion}
        onChange={handleChange}  
        />
        
        </div>


       <div class="col-4">
        
        <label>Universidad / Instituto</label>
        <br/>
        <input type="text" className="form-control" name="f1_universidad" 
        value={frameworkSeleccionado && frameworkSeleccionado.f1_universidad}
        onChange={handleChange}  
        />
        
        </div>

        <div class="col-3">
        
        <label>Ciudad / Departamento</label>
        <br/>
        <input type="text" className="form-control" name="f1_ciudad"  
        value={frameworkSeleccionado && frameworkSeleccionado.f1_ciudad}
        onChange={handleChange}  
        />
        
        </div>

        

        </div>
        
<br/>
        <center><label>-------------------------------------- ITEM 1 --------------------------------------- </label> </center>
         
        <br/>      

   <div className='row'>

        <div class="col-2">
        
        <label>Año</label>
        <br/>
        <input type="text" className="form-control" name="f2_ano"  
         value={frameworkSeleccionado && frameworkSeleccionado.f2_ano}
        onChange={handleChange}  
        />
        
        </div>

        <div class="col-3">
        
        <label>Profesión</label>
        <br/>
        <input type="text" className="form-control" name="f2_profesion" 
          value={frameworkSeleccionado && frameworkSeleccionado.f2_profesion}
        onChange={handleChange}  
        />
        
        </div>


       <div class="col-4">
        
        <label>Universidad / Instituto</label>
        <br/>
        <input type="text" className="form-control" name="f2_universidad"   
         value={frameworkSeleccionado && frameworkSeleccionado.f2_universidad}
        onChange={handleChange}  
        />
        
        </div>

        <div class="col-3">
        
        <label>Ciudad / Departamento</label>
        <br/>
        <input type="text" className="form-control" name="f2_ciudad"  
         value={frameworkSeleccionado && frameworkSeleccionado.f2_ciudad}
        onChange={handleChange}  
        />
        
        </div>

        

        </div>
        
<br/>
        <center><label>-------------------------------------- ITEM 2 --------------------------------------- </label> </center>
         

    <br/>      

   <div className='row'>

        <div class="col-2">
        
        <label>Año</label>
        <br/>
        <input type="text" className="form-control" name="f3_ano"  
        value={frameworkSeleccionado && frameworkSeleccionado.f3_ano}
        onChange={handleChange}  
        />
        
        </div>

        <div class="col-3">
        
        <label>Profesión</label>
        <br/>
        <input type="text" className="form-control" name="f3_profesion" 
         value={frameworkSeleccionado && frameworkSeleccionado.f3_profesion}
        onChange={handleChange}  
        />
        
        </div>


       <div class="col-4">
        
        <label>Universidad / Instituto</label>
        <br/>
        <input type="text" className="form-control" name="f3_universidad"   
         value={frameworkSeleccionado && frameworkSeleccionado.f3_universidad}
        onChange={handleChange}  
        />
        
        </div>

        <div class="col-3">
        
        <label>Ciudad / Departamento</label>
        <br/>
        <input type="text" className="form-control" name="f3_ciudad"  
          value={frameworkSeleccionado && frameworkSeleccionado.f3_ciudad}
        onChange={handleChange}  
        />
        
        </div>

        

        </div>
        
<br/>
        <center><label>-------------------------------------- ITEM 3 --------------------------------------- </label> </center>      
               
         
        <br/>      

<div className='row'>

     <div class="col-2">
     
     <label>Año</label>
     <br/>
     <input type="text" className="form-control" name="f4_ano"  
      value={frameworkSeleccionado && frameworkSeleccionado.f4_ano}
     onChange={handleChange}  
     />
     
     </div>

     <div class="col-3">
     
     <label>Profesión</label>
     <br/>
     <input type="text" className="form-control" name="f4_profesion" 
      value={frameworkSeleccionado && frameworkSeleccionado.f4_profesion}
     onChange={handleChange}  
     />
     
     </div>


    <div class="col-4">
     
     <label>Universidad / Instituto</label>
     <br/>
     <input type="text" className="form-control" name="f4_universidad"  
      value={frameworkSeleccionado && frameworkSeleccionado.f4_universidad}
     onChange={handleChange}  
     />
     
     </div>

     <div class="col-3">
     
     <label>Ciudad / Departamento</label>
     <br/>
     <input type="text" className="form-control" name="f4_ciudad"  
     value={frameworkSeleccionado && frameworkSeleccionado.f4_ciudad}
     onChange={handleChange}  
     />
     
     </div>

     

     </div>
     
<br/>
     <center><label>-------------------------------------- ITEM 4 --------------------------------------- </label> </center>


     <br/>      

<div className='row'>

     <div class="col-2">
     
     <label>Año</label>
     <br/>
     <input type="text" className="form-control" name="f5_ano" 
      value={frameworkSeleccionado && frameworkSeleccionado.f5_ano}
     onChange={handleChange}  
     />
     
     </div>

     <div class="col-3">
     
     <label>Profesión</label>
     <br/>
     <input type="text" className="form-control" name="f5_profesion"  
     value={frameworkSeleccionado && frameworkSeleccionado.f5_profesion}
      onChange={handleChange}  
     />
     
     </div>


    <div class="col-4">
     
     <label>Universidad / Instituto</label>
     <br/>
     <input type="text" className="form-control" name="f5_universidad"   
     value={frameworkSeleccionado && frameworkSeleccionado.f5_universidad}
     onChange={handleChange}  
     />
     
     </div>

     <div class="col-3">
     
     <label>Ciudad / Departamento</label>
     <br/>
     <input type="text" className="form-control" name="f5_ciudad" 
     value={frameworkSeleccionado && frameworkSeleccionado.f5_ciudad}
       onChange={handleChange}  
     />
     
     </div>

     

     </div>
     
<br/>
     <center><label>-------------------------------------- ITEM 5 --------------------------------------- </label> </center>
         
           </>

         
  



       
          
                  </div>
                </div>
              </div>
              



              <div class="accordion-item" style={{backgroundColor: "white", borderColor:"black", borderWidth:"0px"}}>
                <div class="accordion-header center" id="accordionTwo">
                <center>  <h6 class="shadow-sm rounded border" data-bs-toggle="collapse" data-bs-target="#accordionStyleTwo" aria-expanded="false" aria-controls="accordionStyleTwo">Experiencia laboral
                    <svg class="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
                    </svg>
                  </h6> </center>
                </div>
                <div class="accordion-collapse collapse " id="accordionStyleTwo" aria-labelledby="accordionTwo" data-bs-parent="#accordionStyle3" >
                  <div class="accordion-body">
                
                  <>
                  <div className='row'>
        <div class="col-5">
        
        <label>Nombre de empresa</label>
        <br/>
        <input type="text" className="form-control" name="e1_empresa"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e1_empresa}
        />
        
        </div>

        <div class="col-7">
        
        <label>Dirección</label>
        <br/>
        <input type="text" className="form-control" name="e1_direccion"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e1_direccion}
        />
        
        </div>


       <div class="col-4">
        <br/>
        <label>Duración</label>
        <br/>
        <input type="text" className="form-control" name="e1_duracion"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e1_duracion}
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Fecha de inicio</label>
        <br/>
        <input type="text" className="form-control" name="e1_fecha_inicio"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e1_fecha_inicio}
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Fecha de terminación</label>
        <br/>
        <input type="text" className="form-control" name="e1_fecha_terminacion"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e1_fecha_terminacion}
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Jefe inmediato</label>
        <br/>
        <input type="text" className="form-control" name="e1_jefe_inmediato"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e1_jefe_inmediato}
        />
        
        </div>


        <div class="col-4">
        <br/>
        <label>Teléfono / Celular</label>
        <br/>
        <input type="number" className="form-control" name="e1_celular"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e1_celular}
        />
        
        </div>


        <div class="col-4">
        <br/>
        <label>Cargo</label>
        <br/>
        <input type="text" className="form-control" name="e1_cargo"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e1_cargo}
        />
        
        </div>


        <div class="col-12">
        <br/>
        <label>Labores</label>
        <br/>
        <textarea type="text"  rows={4} className="form-control" name="e1_labores"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e1_labores}
        />
        
        </div>


        

        </div>
        
<br/>
        <center><label>-------------------------------------- ITEM 1 --------------------------------------- </label> </center>
         
        <br/>      

        <div className='row'>
        <div class="col-5">
        
        <label>Nombre de empresa</label>
        <br/>
        <input type="text" className="form-control" name="e2_empresa"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e2_empresa}
        />
        
        </div>

        <div class="col-7">
        
        <label>Dirección</label>
        <br/>
        <input type="text" className="form-control" name="e2_direccion"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e2_direccion}
        />
        
        </div>


       <div class="col-4">
        <br/>
        <label>Duración</label>
        <br/>
        <input type="text" className="form-control" name="e2_duracion"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e2_duracion}
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Fecha de inicio</label>
        <br/>
        <input type="text" className="form-control" name="e2_fecha_inicio"   onChange={handleChange} 
         value={frameworkSeleccionado && frameworkSeleccionado.e2_fecha_inicio} 
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Fecha de terminación</label>
        <br/>
        <input type="text" className="form-control" name="e2_fecha_terminacion"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e2_fecha_terminacion} 
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Jefe inmediato</label>
        <br/>
        <input type="text" className="form-control" name="e2_jefe_inmediato"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e2_jefe_inmediato} 
        />
        
        </div>


        <div class="col-4">
        <br/>
        <label>Teléfono / Celular</label>
        <br/>
        <input type="number" className="form-control" name="e2_celular"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e2_celular} 
        />
        
        </div>


        <div class="col-4">
        <br/>
        <label>Cargo</label>
        <br/>
        <input type="text" className="form-control" name="e2_cargo"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e2_cargo} 
        />
        
        </div>


        <div class="col-12">
        <br/>
        <label>Labores</label>
        <br/>
        <textarea type="text"  rows={4} className="form-control" name="e2_labores"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e2_labores} 
        />
        
        </div>


        

        </div>
<br/>
        <center><label>-------------------------------------- ITEM 2 --------------------------------------- </label> </center>
         

    <br/>      

    <div className='row'>
        <div class="col-5">
        
        <label>Nombre de empresa</label>
        <br/>
        <input type="text" className="form-control" name="e3_empresa"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e3_empresa} 
        />
        
        </div>

        <div class="col-7">
        
        <label>Dirección</label>
        <br/>
        <input type="text" className="form-control" name="e3_direccion"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e3_direccion}
        />
        
        </div>


       <div class="col-4">
        <br/>
        <label>Duración</label>
        <br/>
        <input type="text" className="form-control" name="e3_duracion"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e3_duracion}
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Fecha de inicio</label>
        <br/>
        <input type="text" className="form-control" name="e3_fecha_inicio"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e3_fecha_inicio}
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Fecha de terminación</label>
        <br/>
        <input type="text" className="form-control" name="e3_fecha_terminacion"   onChange={handleChange} 
         value={frameworkSeleccionado && frameworkSeleccionado.e3_fecha_terminacion} 
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Jefe inmediato</label>
        <br/>
        <input type="text" className="form-control" name="e3_jefe_inmediato"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e3_jefe_inmediato} 
        />
        
        </div>


        <div class="col-4">
        <br/>
        <label>Teléfono / Celular</label>
        <br/>
        <input type="number" className="form-control" name="e3_celular"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e3_celular} 
        />
        
        </div>


        <div class="col-4">
        <br/>
        <label>Cargo</label>
        <br/>
        <input type="text" className="form-control" name="e3_cargo"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e3_cargo} 
        />
        
        </div>


        <div class="col-12">
        <br/>
        <label>Labores</label>
        <br/>
        <textarea type="text"  rows={4} className="form-control" name="e3_labores"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e3_labores} 
        />
        
        </div>


        

        </div>
        
<br/>
        <center><label>-------------------------------------- ITEM 3 --------------------------------------- </label> </center>      
               
         
        <br/>      

        <div className='row'>
        <div class="col-5">
        
        <label>Nombre de empresa</label>
        <br/>
        <input type="text" className="form-control" name="e4_empresa"   onChange={handleChange} 
        value={frameworkSeleccionado && frameworkSeleccionado.e4_empresa}  
        />
        
        </div>

        <div class="col-7">
        
        <label>Dirección</label>
        <br/>
        <input type="text" className="form-control" name="e4_direccion"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e4_direccion}  
        />
        
        </div>


       <div class="col-4">
        <br/>
        <label>Duración</label>
        <br/>
        <input type="text" className="form-control" name="e4_duracion"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e4_duracion} 
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Fecha de inicio</label>
        <br/>
        <input type="text" className="form-control" name="e4_fecha_inicio"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e4_fecha_inicio} 
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Fecha de terminación</label>
        <br/>
        <input type="text" className="form-control" name="e4_fecha_terminacion"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e4_fecha_terminacion} 
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Jefe inmediato</label>
        <br/>
        <input type="text" className="form-control" name="e4_jefe_inmediato"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e4_jefe_inmediato} 
        />
        
        </div>


        <div class="col-4">
        <br/>
        <label>Teléfono / Celular</label>
        <br/>
        <input type="number" className="form-control" name="e4_celular"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e4_celular} 
        />
        
        </div>


        <div class="col-4">
        <br/>
        <label>Cargo</label>
        <br/>
        <input type="text" className="form-control" name="e4_cargo"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e4_cargo} 
        />
        
        </div>


        <div class="col-12">
        <br/>
        <label>Labores</label>
        <br/>
        <textarea type="text"  rows={4} className="form-control" name="e4_labores"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e4_labores} 
        />
        
        </div>


        

        </div>
     
<br/>
     <center><label>-------------------------------------- ITEM 4 --------------------------------------- </label> </center>


     <br/>      

     <div className='row'>
        <div class="col-5">
        
        <label>Nombre de empresa</label>
        <br/>
        <input type="text" className="form-control" name="e5_empresa"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e5_empresa} 
        />
        
        </div>

        <div class="col-7">
        
        <label>Dirección</label>
        <br/>
        <input type="text" className="form-control" name="e5_direccion"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e5_direccion} 
        />
        
        </div>


       <div class="col-4">
        <br/>
        <label>Duración</label>
        <br/>
        <input type="text" className="form-control" name="e5_duracion"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e5_duracion} 
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Fecha de inicio</label>
        <br/>
        <input type="text" className="form-control" name="e5_fecha_inicio"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e5_fecha_inicio} 
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Fecha de terminación</label>
        <br/>
        <input type="text" className="form-control" name="e5_fecha_terminacion"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e5_fecha_terminacion} 
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Jefe inmediato</label>
        <br/>
        <input type="text" className="form-control" name="e5_jefe_inmediato"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e5_jefe_inmediato}
        />
        
        </div>


        <div class="col-4">
        <br/>
        <label>Teléfono / Celular</label>
        <br/>
        <input type="number" className="form-control" name="e5_celular"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e5_celular}
        />
        
        </div>


        <div class="col-4">
        <br/>
        <label>Cargo</label>
        <br/>
        <input type="text" className="form-control" name="e5_cargo"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.e5_cargo}
        />
        
        </div>


        <div class="col-12">
        <br/>
        <label>Labores</label>
        <br/>
        <textarea type="text"  rows={4} className="form-control" name="e5_labores"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.e5_labores}
        />
        
        </div>


        

        </div>
     
<br/>
     <center><label>-------------------------------------- ITEM 5 --------------------------------------- </label> </center>
         
           </>

         
  



       
          
                  </div>
                </div>
              </div>
              
             


              <div class="accordion-item" style={{backgroundColor: "white", borderColor:"black", borderWidth:"0px"}}>
                <div class="accordion-header center" id="accordionThree">
                <center>  <h6 class="shadow-sm rounded border" data-bs-toggle="collapse" data-bs-target="#accordionStyleThree" aria-expanded="false" aria-controls="accordionStyleThree">Capacitaciones
                    <svg class="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
                    </svg>
                  </h6> </center>
                </div>
                <div class="accordion-collapse collapse " id="accordionStyleThree" aria-labelledby="accordionThree" data-bs-parent="#accordionStyle3" >
                  <div class="accordion-body">
                
                  <>
                  <div className='row'>
      
        <div class="col-12">
        
        <label>Capacitación</label>
        <br/>
        <textarea type="text"  rows={4} className="form-control" name="c1_capacitacion"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.c1_capacitacion}
        />
        
        </div>

        <div class="col-2">
        <br/>
        <label>Año</label>
        <br/>
        <input type="number" className="form-control" name="c1_ano"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.c1_ano}
        />
        
        </div>



       <div class="col-3">
        <br/>
        <label>Hora</label>
        <br/>
        <input type="text" className="form-control" name="c1_hora"   onChange={handleChange} 
        value={frameworkSeleccionado && frameworkSeleccionado.c1_hora} 
        />
        
        </div>

        <div class="col-7">
        <br/>
        <label>Entidad</label>
        <br/>
        <input type="text" className="form-control" name="c1_entidad"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.c1_entidad} 
        />
        
        </div>

        <div class="col-5">
        <br/>
        <label>Departamento / ciudad</label>
        <br/>
        <input type="text" className="form-control" name="c1_d_c"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.c1_d_c} 
        />
        
        </div>

        

       


        

        </div>
        
<br/>
        <center><label>-------------------------------------- ITEM 1 --------------------------------------- </label> </center>
         
        <br/>      

        <div className='row'>
      
        <div class="col-12">
        
        <label>Capacitación</label>
        <br/>
        <textarea type="text"  rows={4} className="form-control" name="c2_capacitacion"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.c2_capacitacion} 
        />
        
        </div>

        <div class="col-2">
        <br/>
        <label>Año</label>
        <br/>
        <input type="number" className="form-control" name="c2_ano"   onChange={handleChange}  
        value={frameworkSeleccionado && frameworkSeleccionado.c2_ano} 
        />
        
        </div>



       <div class="col-3">
        <br/>
        <label>Hora</label>
        <br/>
        <input type="text" className="form-control" name="c2_hora"   onChange={handleChange} 
        value={frameworkSeleccionado && frameworkSeleccionado.c2_hora}  
        />
        
        </div>

        <div class="col-7">
        <br/>
        <label>Entidad</label>
        <br/>
        <input type="text" className="form-control" name="c2_entidad"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.c2_entidad}  
        />
        
        </div>

        <div class="col-5">
        <br/>
        <label>Departamento / ciudad</label>
        <br/>
        <input type="text" className="form-control" name="c2_d_c"   onChange={handleChange}  
         value={frameworkSeleccionado && frameworkSeleccionado.c2_d_c}  
        />
        
        </div>

        

       


        

        </div>
<br/>
        <center><label>-------------------------------------- ITEM 2 --------------------------------------- </label> </center>
         

    <br/>      

    <div className='row'>
      
      <div class="col-12">
      
      <label>Capacitación</label>
      <br/>
      <textarea type="text"  rows={4} className="form-control" name="c3_capacitacion"   onChange={handleChange}  
       value={frameworkSeleccionado && frameworkSeleccionado.c3_capacitacion} 
      />
      
      </div>

      <div class="col-2">
      <br/>
      <label>Año</label>
      <br/>
      <input type="number" className="form-control" name="c3_ano"   onChange={handleChange}  
      value={frameworkSeleccionado && frameworkSeleccionado.c3_ano}
      />
      
      </div>



     <div class="col-3">
      <br/>
      <label>Hora</label>
      <br/>
      <input type="text" className="form-control" name="c3_hora"   onChange={handleChange}  
       value={frameworkSeleccionado && frameworkSeleccionado.c3_hora}
      />
      
      </div>

      <div class="col-7">
      <br/>
      <label>Entidad</label>
      <br/>
      <input type="text" className="form-control" name="c3_entidad"   onChange={handleChange}  
      value={frameworkSeleccionado && frameworkSeleccionado.c3_entidad}
      />
      
      </div>

      <div class="col-5">
      <br/>
      <label>Departamento / ciudad</label>
      <br/>
      <input type="text" className="form-control" name="c3_d_c"   onChange={handleChange}  
      value={frameworkSeleccionado && frameworkSeleccionado.c3_d_c}
      />
      
      </div>

      

     


      

      </div>
        
<br/>
        <center><label>-------------------------------------- ITEM 3 --------------------------------------- </label> </center>      
               
         
        <br/>      

        <div className='row'>
      
      <div class="col-12">
      
      <label>Capacitación</label>
      <br/>
      <textarea type="text"  rows={4} className="form-control" name="c4_capacitacion"   onChange={handleChange}  
      value={frameworkSeleccionado && frameworkSeleccionado.c4_capacitacion}
      />
      
      </div>

      <div class="col-2">
      <br/>
      <label>Año</label>
      <br/>
      <input type="number" className="form-control" name="c4_ano"   onChange={handleChange}  
      value={frameworkSeleccionado && frameworkSeleccionado.c4_ano}
      />
      
      </div>



     <div class="col-3">
      <br/>
      <label>Hora</label>
      <br/>
      <input type="text" className="form-control" name="c4_hora"   onChange={handleChange} 
      value={frameworkSeleccionado && frameworkSeleccionado.c4_hora} 
      />
      
      </div>

      <div class="col-7">
      <br/>
      <label>Entidad</label>
      <br/>
      <input type="text" className="form-control" name="c4_entidad"   onChange={handleChange}  
      value={frameworkSeleccionado && frameworkSeleccionado.c4_entidad} 
      />
      
      </div>

      <div class="col-5">
      <br/>
      <label>Departamento / ciudad</label>
      <br/>
      <input type="text" className="form-control" name="c4_d_c"   onChange={handleChange} 
      value={frameworkSeleccionado && frameworkSeleccionado.c4_d_c}  
      />
      
      </div>

      

     


      

      </div>
     
<br/>
     <center><label>-------------------------------------- ITEM 4 --------------------------------------- </label> </center>


     <br/>      

     <div className='row'>
      
      <div class="col-12">
      
      <label>Capacitación</label>
      <br/>
      <textarea type="text"  rows={4} className="form-control" name="c5_capacitacion"   onChange={handleChange} 
       value={frameworkSeleccionado && frameworkSeleccionado.c5_capacitacion} 
      />
      
      </div>

      <div class="col-2">
      <br/>
      <label>Año</label>
      <br/>
      <input type="number" className="form-control" name="c5_ano"   onChange={handleChange}  
      value={frameworkSeleccionado && frameworkSeleccionado.c5_ano} 
      />
      
      </div>



     <div class="col-3">
      <br/>
      <label>Hora</label>
      <br/>
      <input type="text" className="form-control" name="c5_hora"   onChange={handleChange}  
      value={frameworkSeleccionado && frameworkSeleccionado.c5_hora} 
      />
      
      </div>

      <div class="col-7">
      <br/>
      <label>Entidad</label>
      <br/>
      <input type="text" className="form-control" name="c5_entidad"   onChange={handleChange} 
      value={frameworkSeleccionado && frameworkSeleccionado.c5_entidad}  
      />
      
      </div>

      <div class="col-5">
      <br/>
      <label>Departamento / ciudad</label>
      <br/>
      <input type="text" className="form-control" name="c5_d_c"   onChange={handleChange} 
      value={frameworkSeleccionado && frameworkSeleccionado.c5_d_c}  
      />
      
      </div>

      

     


      

      </div>
     
<br/>
     <center><label>-------------------------------------- ITEM 5 --------------------------------------- </label> </center>
         
           </>

         
  



       
          
                  </div>
                </div>
              </div>




              <div class="accordion-item" style={{backgroundColor: "white", borderColor:"black", borderWidth:"0px"}}>
                <div class="accordion-header center" id="accordionFour">
                <center>  <h6 class="shadow-sm rounded border" data-bs-toggle="collapse" data-bs-target="#accordionStyleFour" aria-expanded="false" aria-controls="accordionStyleFour">Referencias
                    <svg class="bi bi-arrow-down-short" width="24" height="24" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"></path>
                    </svg>
                  </h6> </center>
                </div>
                <div class="accordion-collapse collapse " id="accordionStyleFour" aria-labelledby="accordionFour" data-bs-parent="#accordionStyle3" >
                  <div class="accordion-body">
                
                  <>
                  <div className='row'>
      
        <div class="col-5">
        
        <label>Nombre referencia</label>
        <br/>
        <input type="text"  className="form-control" name="r1_nombre"   onChange={handleChange}  
        />
        
        </div>

        <div class="col-7">
        
        <label>Empresa</label>
        <br/>
        <input type="text" className="form-control" name="r1_empresa"   onChange={handleChange}  
        />
        
        </div>



       <div class="col-5">
        <br/>
        <label>Profesión</label>
        <br/>
        <input type="text" className="form-control" name="r1_profesion"   onChange={handleChange}  
        />
        
        </div>

        <div class="col-4">
        <br/>
        <label>Celular</label>
        <br/>
        <input type="number" className="form-control" name="r1_celular"   onChange={handleChange}  
        />
        
        </div>

   
        

        </div>
        
<br/>
        <center><label>-------------------------------------- ITEM 1 --------------------------------------- </label> </center>
         
        <br/>      
        <br/> 

        <div className='row'>
      
      <div class="col-5">
      
      <label>Nombre referencia</label>
      <br/>
      <input type="text"  className="form-control" name="r2_nombre"   onChange={handleChange}  
      />
      
      </div>

      <div class="col-7">
      
      <label>Empresa</label>
      <br/>
      <input type="text" className="form-control" name="r2_empresa"   onChange={handleChange}  
      />
      
      </div>



     <div class="col-5">
      <br/>
      <label>Profesión</label>
      <br/>
      <input type="text" className="form-control" name="r2_profesion"   onChange={handleChange}  
      />
      
      </div>

      <div class="col-4">
      <br/>
      <label>Celular</label>
      <br/>
      <input type="number" className="form-control" name="r2_celular"   onChange={handleChange}  
      />
      
      </div>

 
      

      </div>
<br/>
        <center><label>-------------------------------------- ITEM 2 --------------------------------------- </label> </center>
         
        <br/>  
    <br/>      

    <div className='row'>
      
      <div class="col-5">
      
      <label>Nombre referencia</label>
      <br/>
      <input type="text"  className="form-control" name="r3_nombre"   onChange={handleChange}  
      />
      
      </div>

      <div class="col-7">
      
      <label>Empresa</label>
      <br/>
      <input type="text" className="form-control" name="r3_empresa"   onChange={handleChange}  
      />
      
      </div>



     <div class="col-5">
      <br/>
      <label>Profesión</label>
      <br/>
      <input type="text" className="form-control" name="r3_profesion"   onChange={handleChange}  
      />
      
      </div>

      <div class="col-4">
      <br/>
      <label>Celular</label>
      <br/>
      <input type="number" className="form-control" name="r3_celular"   onChange={handleChange}  
      />
      
      </div>

 
      

      </div>
        
<br/>
        <center><label>-------------------------------------- ITEM 3 --------------------------------------- </label> </center>      
               
        <br/>  
        <br/>      

        <div className='row'>
      
      <div class="col-5">
      
      <label>Nombre referencia</label>
      <br/>
      <input type="text"  className="form-control" name="r4_nombre"   onChange={handleChange}  
      />
      
      </div>

      <div class="col-7">
      
      <label>Empresa</label>
      <br/>
      <input type="text" className="form-control" name="r4_empresa"   onChange={handleChange}  
      />
      
      </div>



     <div class="col-5">
      <br/>
      <label>Profesión</label>
      <br/>
      <input type="text" className="form-control" name="r4_profesion"   onChange={handleChange}  
      />
      
      </div>

      <div class="col-4">
      <br/>
      <label>Celular</label>
      <br/>
      <input type="number" className="form-control" name="r4_celular"   onChange={handleChange}  
      />
      
      </div>

 
      

      </div>
     
<br/>
     <center><label>-------------------------------------- ITEM 4 --------------------------------------- </label> </center>


     <br/>      
     <br/>  

     <div className='row'>
      
      <div class="col-5">
      
      <label>Nombre referencia</label>
      <br/>
      <input type="text"  className="form-control" name="r5_nombre"   onChange={handleChange}  
      />
      
      </div>

      <div class="col-7">
      
      <label>Empresa</label>
      <br/>
      <input type="text" className="form-control" name="r5_empresa"   onChange={handleChange}  
      />
      
      </div>



     <div class="col-5">
      <br/>
      <label>Profesión</label>
      <br/>
      <input type="text" className="form-control" name="r5_profesion"   onChange={handleChange}  
      />
      
      </div>

      <div class="col-4">
      <br/>
      <label>Celular</label>
      <br/>
      <input type="number" className="form-control" name="r5_celular"   onChange={handleChange}  
      />
      
      </div>

 
      

      </div>
     
<br/>
     <center><label>-------------------------------------- ITEM 5 --------------------------------------- </label> </center>

     
         
           </>


           


       
          
                  </div>
                </div>
              </div>
              
              <br/>
              <br/>

              {imageURL 
?
 <img
src={imageURL}
alt= "mi firma"
style={{
  display:"block",
  margin:" 0 auto",
  //border:"1px solid black",
  width: "300px",
 // Height: "100px"
 
}}




/>
: null
}

              <center>

              <img style={{borderRadius:"10px"}} onChange={handleChange} src={"https://aliraweb.com/apiAlira2/hoja_de_vida/Imagenes/"+frameworkSeleccionado.firma_trabajador} width="150px"/>
                 <div class="col-5">

<input name="documento_empresa"  className="form-control" type="text" placeholder="Nombres completos" value={theUser.name} autoComplete="off" readOnly
style={{border:"0", textAlign:"center", backgroundColor:"white"}} />
             
            </div>

            </center>
             <center> 
              <div class="col-2" style={{marginTop:"-10px"}}>

<input name="documento"  className="form-control" type="text" placeholder="Documento" value={theUser.documento} autoComplete="off" readOnly
style={{border:"0", textAlign:"center", backgroundColor:"white"}} />
             
            </div>

            </center>

      
  


            </div>

   </div>
   {
/*
 <div style={{paddingTop:"10px"}}>
   <button className="btn btn-primary " onClick={loadData} disabled={loading} ><i class="fa-solid fa-pen-to-square"></i> Crear hoja de vida</button>
   </div> */ }

 
   <div style={{display:"none"}}>
  <input name="id"  className="form-control" type="text" placeholder="I" value={frameworkSeleccionado && frameworkSeleccionado.id} autoComplete="off" readOnly />
   <input type="text" id="txtFoto" name="txtFoto"  value={imageURL}/>
   </div>

   </form>

   <Popup  modal  position="right center"
closeOnDocumentClick={false}
>
 {close => (
  <>
  <SignatureCanvas 
  ref={sigCanvas}

  canvasProps={{className: "sigCanvas" }}
/>
<button onClick={close}>Cerrar</button>
<button onClick={limpiar_firma}>Borrar firma</button>
<button onClick={guardar_firma}>Guardar firma</button>
</>
 )}
  </Popup>
 
   </div>
 </ModalBody>
 <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 <ModalFooter>
   
  
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">USUARIO</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-7">

<label>Nombre completos:</label>
<br/>
      <input type="text" className="form-control"  name="name" onChange={handleChange}
        />
  </div>

<div class="col-5">

<label>Documento:</label>
<br/>
      <input type="text" className="form-control"  name="documento" onChange={handleChange}
        />
  </div>

  <div class="col-6">
  <br/>
  <label>Contraseña:</label>
  <br/>
  <input type="text"  className="form-control" required minLength={8} maxLength={15}  name="clave" onChange={handleChange} 
   />
   <label>Mínimo 8 caracteres</label>
  <br/>
  </div>


  
  <div className="col-6">
    
   <br/>
                 <label>Agregar cargo:</label>
              <select name="cargo" className="form-select" onChange={handleChange}>
                   <option>--Seleccionar cargo--</option>
                   {
                     cargo.map( (getcon)=>(
                      
                   <option key={getcon.id} value={getcon.cargo}> {getcon.cargo}</option>
                
                     ))
                    
                }

                              
                 </select>
               
            </div> 
  
  <div style={{display:"none"}}>
  <input name="id_empresa_usuario"  className="form-control" type="text" placeholder="Id empresa" value={theUser.id} autoComplete="off" readOnly />
  <input name="nombre_empresa"  className="form-control" type="text" placeholder="Nombre emperesa" value={theUser.name} autoComplete="off" readOnly />
  <input name="documento_empresa"  className="form-control" type="text" placeholder="Documento emperesa" value={theUser.documento} autoComplete="off" readOnly />
  

  
  </div>
  
 

  </div>

   
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Hojas_de_vida
