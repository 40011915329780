import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
import MaterialTable from "material-table";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  documento: "",
  name: "",
  cargo: "",
  clave: "",
  id_empresa_usuario: "",
  nombre_empresa: "",
  documento_empresa: ""
};

function Pruebas () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://aliraweb.com/apiAlira2/pruebas/pruebas.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    documento:'',
    name:'',
    cargo:'',
    clave:'',
    id_empresa_usuario:'',
    nombre_empresa:'',
    documento_empresa:'',
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };



    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }

  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(`https://aliraweb.com/apiAlira2/pruebas/pruebas.php?id_empresa_usuario=${global.idprincipal}`)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }


   const peticionPut = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("cargo", frameworkSeleccionado.cargo);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "PUT");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.name= frameworkSeleccionado.name;
         framework.documento= frameworkSeleccionado.documento;
         framework.cargo= frameworkSeleccionado.cargo;
        framework.clave = frameworkSeleccionado.clave;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }



  const peticionPost = async() =>{
    var f = new FormData();
    f.append("documento", frameworkSeleccionado.documento);
    f.append("name", frameworkSeleccionado.name);
    f.append("cargo", frameworkSeleccionado.cargo);
    f.append("id_empresa_usuario", theUser.id);
    f.append("nombre_empresa", theUser.name);
    f.append("documento_empresa", theUser.documento);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }


  const [cargo, setCountry]= useState([]);


  useEffect( ()=>{

    const interval = setInterval(() => {
    const getcountry= async ()=>{
   const req= await fetch(`https://aliraweb.com/apiAlira2/pruebas/usuarios.php?usuarioE=${global.idprincipal}`)
  // const req= await fetch("https://parqueoenvia.co/apiParqueoenvia/zonas/country.php");
      const getres= await req.json();
      console.log(getres);
      setCountry(await getres);
  
    }
    getcountry();

}, 2000);

return () => setInterval(interval);
  
  
   },[]);

  

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },
    
    {
      title:"DOCUMENTO",
      field: "documento"
    },

    {
        title:"NOMBRE",
        field: "name"
      },

    {
      title:'NOMBRE PRUEBA',
      field:'nombre_archivo'
    },

    {
        title:'FECHA',
        field:'fecha_creacion'
      },
      

    
  ];


  
    
    if(isAuth && theUser.tipo_usuario =="empresa")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="row">




{/*<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>PUNTOS SUSPENDIDOS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_suspendidas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
  </div> */}

</div>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">{theUser.name}</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<><button className="btn btn-success" onClick={()=>abrirCerrarModalInsertar()}> <i class="fas fa-file-archive fa-2x"></i>  AGREGAR PRUEBAS DE CONOCIMIENTO Y PSICOTÉCNICAS</button></> }
backIconButtonText='Página anterior'
pagination
fixedHeader


actions={[

    {
        icon : 'visibility',
        tooltip: 'Ver prueba',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
 
     
              {
                  icon: 'delete',
                  tooltip: 'eliminar',
                  onClick: (event, framework) => seleccionarFramework(framework, "Eliminar")
              },

             
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar'
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea eliminar la prueba del usuario {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar} style={{maxWidth:"800px"}} >
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">VER PRUEBA</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

  

<iframe src={"https://docs.google.com/gview?url=https://aliraweb.com/apiAlira2/pruebas/pdf/"+frameworkSeleccionado.archivo+"&embedded=true"}  frameborder="0" style={{width:"100%", height:"400px"}} ></iframe>

   <div class="col-7">
    <br/>

<label>Nombre de la prueba:</label>
<br/>
      <input type="text" className="form-control"  name="name" onChange={handleChange}
      value={frameworkSeleccionado && frameworkSeleccionado.nombre_archivo}  readOnly />
  </div>

  <div class="col-5">
  <br/>
  <label>Fecha de creación</label>
  <br/>
  <input type="text" className="form-control" name="documento" onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.fecha_creacion} readOnly/>
  
  </div>
  
  
  
            <div class="col-7">
            <br/>
  <label>Nombre trabajador</label>
  <br/>
  <input type="text" className="form-control" name="cargo" onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.name} readOnly/>
  
  </div>


  <div class="col-5">
            <br/>
  <label>Documento trabajador</label>
  <br/>
  <input type="text" className="form-control" name="cargo" onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.documento} readOnly/>
  
  </div>



   </div>
   </div>
 </ModalBody>
 <ModalFooter>
  {/* <button className="btn btn-primary " onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Editar</button>{" "} */}
   <button className="btn btn-primary w-100" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Aceptar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">AGREGAR PRUEBA</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <form method="post" action="https://aliraweb.com/apiAlira2/pruebas/index.php" target="_parent" name="reset" encType='multipart/form-data'  noValidate>
   <div className="row">

   <div class="col-12">

<label>Nombre de la prueba:</label>
<br/>
      <input type="text" className="form-control"  name="nombre_archivo" onChange={handleChange}
        />
  </div>


  

  <div class="col-12">
  
<br/>
  <div class="form-group">
  
                  <label class="form-label" for="customFile">Subir PDF</label>
                  <input class="form-control border-0 form-control-clicked" id="txtFoto2" name="txtFoto2" type="file"
                 accept="application/pdf"
                  onChange={handleChange} />
                </div>
              
  
       </div>
  

  
  <div className="col-12">
    
   <br/>
                 <label>Agregar usuario:</label>
              <select name="documento" className="form-select" onChange={handleChange}>
                   <option>--Seleccionar usuario--</option>
                   {
                     cargo.map( (getcon)=>(
                      
                   <option key={getcon.id} value={getcon.documento}> {getcon.documento + " - " + getcon.name}</option>
                
                     ))
                    
                }

                              
                 </select>
               
            </div> 


            <div className="col-12">
    
    <br/>
                  <label>Confirmar usuario:</label>
               <select name="name" className="form-select" onChange={handleChange}>
                    <option>--Seleccionar usuario--</option>
                    {
                      cargo.map( (getcon)=>(
                       
                    <option key={getcon.id} value={getcon.name}> {getcon.documento + " - " + getcon.name}</option>
                 
                      ))
                     
                 }
 
                               
                  </select>
                
             </div> 
  
  <div style={{display:"none"}}>
  <input name="id_empresa"  className="form-control" type="text" placeholder="Id empresa" value={theUser.id} autoComplete="off" readOnly />
  <input name="nombre_empresa"  className="form-control" type="text" placeholder="Nombre emperesa" value={theUser.name} autoComplete="off" readOnly />
  <input name="documento_empresa"  className="form-control" type="text" placeholder="Documento emperesa" value={theUser.documento} autoComplete="off" readOnly />
  

  
  </div>
  
 

  </div>
  <br/>
  <button className="btn btn-primary w-100"  onClick={loadData} disabled={loading}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}

  </form>
   </div>
 </ModalBody>
 <ModalFooter>
   
   <button className="btn btn-danger w-100" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Pruebas
